import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import { isEnableBed } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import reservationStoreModule from '../reservationStoreModule';

export default function useReservationList() {
  const STORE_MODULE_NAME = 'reservation';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, reservationStoreModule);
  }
  const { t } = useI18nUtils();
  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {

    id: 0,
    //
    apartment: null,
    room: null,
    bed: null,
    //
    priceForRent: 0,
    deposit: 0,
    totalDeposit: 0,
    depositMonthObject: null,
    depositDate: moment(new Date()),
    checkinDate: moment(new Date()).add(5, 'days'),
    note: '',
    tenant: null,
    sale: null,
    cashbook: null,
    depositTemplate: null,
    refund: 0,
    refundReason: '',
    paymentPeriod: null,
    billingDate: moment(new Date()).add(5, 'days'),
    startDate: moment(new Date()).add(5, 'days'),
    endDate: moment(new Date()).add(5, 'days').add(6, 'months'),
    contractMonth: { title: '6 tháng', value: 6 },
    numberTenants: 1,
    fees: [],
    lead: null,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const contract = ref({});
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'reservation') && !vm.$can('delete', 'reservation');

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Phòng/Giường'),
      field: 'location',
    },
    {
      label: ('Giá thuê'),
      field: 'priceForRent',
      type: 'number',
      width: '130px',
    },
    {
      label: t('Tiền cọc'),
      field: 'deposit',
      type: 'number',
      width: '130px',
    },
    {
      label: t('Ngày cọc'),
      field: 'depositDate',
      width: '120px',
    },
    {
      label: t('Ngày vào'),
      field: 'checkinDate',
    },
    {
      label: t('Khách cọc'),
      field: 'tenant.name',
    },
    {
      label: t('Trạng thái'),
      field: 'status',
    },

  ];
  const rows = ref([]);
  const analytics = ref(null);
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const status = ref({ id: 1, name: t("Chờ ký hợp đồng") });
  const month = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: { status: 1 },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchReservations = () => {
    isLoading.value = false;
    store
      .dispatch('reservation/fetchReservations', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = false;
    store
      .dispatch('reservation/fetchAnalytics', serverParams.value)
      .then(response => {
        analytics.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchReservations();
    fetchAnalytics();
  };

  const exportData = () => {
    store
      .dispatch('reservation/exportReservations', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteReservations = reservations => {
    store
      .dispatch('reservation/deleteReservations', {
        ids: reservations.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const setContractItem = val => {
    if (val && val.invoice && val.invoice.paid < val.invoice.total && val.invoice.paid < val.deposit) {
      toastification.showToastErrorWithMessage('Số tiền tạm đặt cọc chưa thu đủ nên không thể lập hợp đồng. Vui lòng thu đủ hoặc cập nhật tiền tạm cọc bằng với số tiền đã thu');
      return;
    }
    contract.value = {

      id: 0,
      //
      apartment: val.apartment,
      room: { ...val.room, reservations: [val] },
      bed: val.bed ? { ...val.bed, reservations: [val] } : null,
      //
      contractNumber: '',
      signDate: moment(new Date()).toDate(),
      startDate: moment(val.startDate).isValid() ? moment(val.startDate).toDate() : moment(new Date()).toDate(),
      endDate: moment(val.endDate).isValid() ? moment(val.endDate).toDate() : moment(new Date()).add(1, 'Y').subtract(1, 'd').toDate(),
      //
      price: val.priceForRent,
      paymentPeriod: val.paymentPeriod,
      billingDate: moment(val.billingDate).isValid() ? moment(val.billingDate).toDate() : moment(new Date()).toDate(),
      //
      reservation: val,
      deposit: val.totalDeposit > 0 ? val.totalDeposit : val.room.deposit,
      reservationDeposit: val.deposit,
      remainDeposit: Math.abs(val.room.deposit - val.deposit),
      promotionMonth: val.promotionMonth ?? 0,
      promotionPricePerMonth: val.promotionPricePerMonth ?? 0,

      // tenant
      contractTenants: [],
      tenants: [],
      fees: [],
      contractFees: [],
    };
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.value;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    blankItem,
    item,
    contract,
    analytics,
    columns,
    rows,
    apartment,
    room,
    bed,
    status,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    month,
    exportData,
    setContractItem,
    fetchData,
    fetchReservations,
    deleteReservations,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    resolveColWidthIfDisableBed,
    t,
  };
}

<template>
  <div class="reservation-list-container">
    <b-row
      v-if="analytics"
      class="match-height"
    >

      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          :title="t('Tất cả')"
          :value="analytics.total"
          icon="BookmarkIcon"
          color="secondary"
        />
      </b-col>

      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          :title="t('Chờ ký HĐ')"
          :value="analytics.totalActive"
          icon="BookmarkIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          :title="t('Đã ký HĐ')"
          :value="analytics.totalSignContract"
          icon="BookmarkIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          :title="t('Bỏ cọc')"
          :value="analytics.totalCancel"
          icon="BookmarkIcon"
          color="secondary"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Đặt cọc') }}</b-card-title>
        <crud-buttons
          :name="t('Đặt cọc')"
          :selected-rows="selectedRows"
          modal="modal-reservation"
          :hide-delete="!$can('delete', 'reservation')"
          :enable-add="$can('create', 'reservation')"
          :show-export="true"
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col >
            <select-reservation-status
              v-model="status"
              :default-value="status"
            />
          </b-col>
          <b-col>
            <select-apartment v-model="apartment" />
          </b-col>
          <b-col
          ><select-room
            v-model="room"
            :apartment="apartment"
          /></b-col>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"

          >
            <select-bed
              v-model="bed"
              :room="room"
            />
          </b-col>
          <b-col>
            <select-month v-model="month"></select-month>
          </b-col>
          <!-- <b-col md="3">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col> -->
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-reservation-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Code -->
                <span v-else-if="props.column.field === 'location'">
                  <span v-if="props.row.bed">Giường: {{ props.row.bed.name }}<br></span>

                  <span v-if="props.row.room">Phòng: {{ props.row.room.name }}<br></span>

                  <span
                    v-if="props.row.apartment"
                    class="text-muted"
                  >
                    {{ t('Tòa nhà') }}: {{ props.row.apartment.name }}
                    <br>
                  </span>
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'priceForRent'">
                  {{ Number(props.row.priceForRent).toLocaleString() }} đ
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'deposit'">
                  {{ Number(props.row.deposit).toLocaleString() }} đ
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'tenant.name'">
                  <span v-if="props.row.tenant">{{ props.row.tenant.name }}</span>
                  <small
                    v-if="props.row.note"
                    class="text-muted"
                  ><br>{{ t('Ghi chú') }}: {{ props.row.note }}</small>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    v-if="props.row.status"
                    pill
                    :variant="props.row.status.variant"
                  >{{ props.row.status.name }}</b-badge>
                </span>

                <span v-else-if="['depositDate', 'checkinDate'].includes(props.column.field)">
                  {{ parseDateToString(props.row[props.column.field]) }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('Đặt cọc')"
                    modal="modal-reservation"
                    :disable-edit="parseInt(props.row.status.id, 10) !== 1"
                    :disable-delete="parseInt(props.row.status.id, 10) !== 1"
                    :hide-delete="!$can('delete', 'reservation')"
                    :hide-edit="!$can('update', 'reservation')"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  >
                    <template

                      slot="other-buttons"
                    >
                      <b-button
                        v-if="parseInt(props.row.status.id, 10) === 1 && $can('create', 'contract')"
                        v-b-tooltip.hover.v-primary
                        v-b-modal.modal-contract
                        :disabled="props.row.invoice && props.row.invoice.paid < props.row.deposit"
                        variant="primary"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Ký hợp đồng')"
                        @click="setContractItem(props.row)"
                      >
                        <feather-icon icon="FeatherIcon" />
                      </b-button>
                      <b-button
                        v-if="parseInt(props.row.status.id, 10) === 1 && $can('update', 'reservation')"
                        v-b-tooltip.hover.v-warning
                        v-b-modal.modal-reservation-cancel
                        variant="warning"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Hủy cọc')"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="DeleteIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- modal -->
    <reservation-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <reservation-cancel-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <contract-modal
      ref="contractModalRef"
      :item="contract"
      :disable-property-select="true"
      @refetch-data="fetchData"
    />
    <reservation-detail-modal :reservation-id="item.id" />

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BButton,
  VBTooltip,
  VBModal,
  BBadge,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import { VueGoodTable } from 'vue-good-table';
import CrudButtons from '@/views/components/CrudButtons.vue';
import TableActions from '@/views/components/TableActions.vue';
// eslint-disable-next-line import/no-cycle
import CardStatistic from '@/views/components/CardStatistic.vue';
import SelectReservationStatus from '@/views/components/SelectReservationStatus.vue';
import { parseDateToString } from '@/auth/utils';
import useReservationList from './useReservationList';
import ReservationModal from '../modal/ReservationModal.vue';
import ReservationCancelModal from '../cancel-modal/ReservationCancelModal.vue';
import ContractModal from '../../contract/modal/ContractModal.vue';
import ReservationDetailModal from '../detail/ReservationDetailModal.vue';
import SelectMonth from '@/views/components/SelectMonth.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    ReservationModal,
    SelectApartment,
    SelectRoom,
    SelectBed,
    VueGoodTable,
    CrudButtons,
    TableActions,
    CardStatistic,
    SelectReservationStatus,
    ReservationCancelModal,
    ContractModal,
    ReservationDetailModal,
    SelectMonth
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const {
      item,
      contract,
      analytics,
      columns,
      rows,
      apartment,
      room,
      bed,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      month,
      exportData,
      setContractItem,
      fetchData,
      fetchReservations,
      deleteReservations,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resolveColWidthIfDisableBed,
      t,
    } = useReservationList();

    return {
      item,
      contract,
      analytics,
      columns,
      rows,
      apartment,
      room,
      bed,
      status,
      selectedRows,
      searchTerm,
      month,
      isLoading,
      totalRecords,
      serverParams,
      exportData,
      setContractItem,
      fetchData,
      fetchReservations,
      deleteReservations,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      parseDateToString,
      resolveColWidthIfDisableBed,
      t,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(reservation) {
      const deleteObjects = reservation && reservation.id > 0 ? [reservation] : this.selectedRows;
      this.deleteReservations(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.reservation-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 120px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 100px !important;
            }
            &:nth-child(4) {
               min-width: 180px !important;
            }
            &:nth-child(9) {
               min-width: 180px !important;
            }

          }
        }
      }
    }
}
</style>
